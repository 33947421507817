import { Pagination, Select } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'
import IconLeftArrow from 'src/icons/IconLeftArrow'
import IconRightArrow from 'src/icons/IconRightArrow'

import ResultsPerPage from '../ResultsPerPage/ResultsPerPage'

export interface PaginationAreaProps {
  page: number
  totalPages: number
  resultsPerPage?: number
  setResultsPerPage?: (value: number) => void
  onPageChange: (page: number) => void
}

const PaginationArea = ({
  page,
  totalPages = 1,
  resultsPerPage = null,
  setResultsPerPage = () => {},
  onPageChange,
}: PaginationAreaProps) => {
  return (
    <div className="mt-auto grid grid-cols-6 items-center border-t pt-3">
      <div className="flex items-center">
        {page > 1 && (
          <Button
            variant="transparent"
            text="Previous"
            lefticon={<IconLeftArrow />}
            onClick={() => onPageChange(page - 1)}
          />
        )}
      </div>
      <div className="invisible">placeholder</div>
      <Pagination
        total={totalPages}
        value={page}
        onChange={onPageChange}
        color={'rgba(249, 250, 251, 1)'}
        classNames={{
          root: 'col-span-2 flex justify-center',
          control: 'text-doubleNickel-gray-600 text-sm border-none',
        }}
        withControls={false}
      />
      <div className="ml-auto">
        {resultsPerPage && (
          <ResultsPerPage
            resultsPerPage={resultsPerPage}
            setResultsPerPage={setResultsPerPage}
          />
        )}
      </div>

      <div className="ml-auto flex">
        {page < totalPages && (
          <Button
            onClick={() => onPageChange(page + 1)}
            variant="transparent"
            text="Next"
            righticon={<IconRightArrow />}
          />
        )}
      </div>
    </div>
  )
}

export default PaginationArea
