import { Select } from '@mantine/core'

export interface ResultsPerPageProps {
  resultsPerPage?: number
  setResultsPerPage?: (value: number) => void
}

const ResultsPerPage = ({
  resultsPerPage = null,
  setResultsPerPage = () => {},
}: ResultsPerPageProps) => {
  return (
    <div className="flex flex-row items-center gap-2 text-sm font-medium text-doubleNickel-gray-700">
      Results per page
      <Select
        size="xs"
        className="w-20"
        data={[
          { value: '10', label: '10' },
          { value: '20', label: '20' },
          { value: '50', label: '50' },
          { value: '100', label: '100' },
        ]}
        value={resultsPerPage.toString()}
        onChange={(value) => setResultsPerPage(parseInt(value))}
      />
    </div>
  )
}

export default ResultsPerPage
